import React, { useEffect, useState } from "react";
import { Row, Col } from "../../../components/Component";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import DatePicker from "react-datepicker";
import { Badge, Toast } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NoFounds from "../../../images/No Result.png";
import NoFounds1 from "../../../images/placeholderimageforallproject.jpg";
import Cookies from "js-cookie";
import { getAllCompanies } from "../../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { useTheme } from "../../../layout/provider/Theme";
import { getAvailableSlot } from "../../../http/get/getApi";
import { getAvailableSlot1 } from "../../../http/get/getApi";
import { FaBook } from "react-icons/fa";
import book from "../../../jsonfiles/Booking.json";
import NoFounds2 from "../../../images/Login-No-Background.png";
import conatct from "../../../jsonfiles/Contact.json";

import { Links } from "../../../jsonfiles/Links";
import {  convertTo12HourFormatphoto, currentTime, getActiveEnvironmentVariable } from "../../../utils/Utils";

const timeData = [{ start_time: "12:00:00", end_time: "24:00:00" }];

const startTime = new Date();
const endTime = new Date();

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
    </React.Fragment>
  );
};

const CalenderApp = ({
  events1,
  events,
  onEdit,
  setModal1,
  setFormDataSlot,
  formDataSlot,
  modalTop,
  setModalTop,
  showcalendar,
  setShowcalendar,
  setShowicon,
  showicon,
  slotList
}) => {
  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(events1);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState();
  const [edit, updateEditModal] = useState(false);
  const [eventid, setEventId] = useState();

  const activeVariable = getActiveEnvironmentVariable();

  const [slotTransormData, setSlotTransformData] = useState([]);

  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });

  const naviget = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams();
  const { key } = useParams();

  useEffect(() => {
    updateEvents(events);
  }, [events]);

  const { skin } = useTheme();

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {};
    newEvent = {
      id: event.id,
      key: event.key,
      className: theme.value,
      type: theme,
      title: formData.title,
      start: event.start,
      end: event.end,
      description: formData.description,
    };
    onEdit(newEvent);
    settheme("");
    toggleEdit();
  };

  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  function getEventIdNumeric(eventId) {
    const parts = eventId?.split("-");
    return parts.pop();
  }

  // const handleEventClick = (info) => {
  //   setShowcalendar(false);
  //   setShowicon(true);
  //   const event = mockEvents?.find((item) => item?.id === info?.event?._def?.publicId);
  //   const fullId = info?.event?._def?.publicId;
  //   const eventIdS = fullId?.replace(/\D/g, ""); // This will give "101"
  //   const eventId = Number(eventIdS);

  //   setFormData((prevData) => ({
  //     ...prevData, // Spread the previous form data
  //     id: eventId, // Set the id as eventId
  //   }));
  //   setEventId(eventId);
  //   const event1 = slotTransormData?.find((item) => item?.id === eventId);
  //   setSelectedDate(event1.start_date);
  //   setFormDataSlot({
  //     ...formDataSlot,

  //     eventDate: event1.start_date,
  //     startTime: null,
  //     endTime: null,
  //   });

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     startdate: new Date(event1?.start_date),
  //     enddate: new Date(event1?.end_date),
  //     From: new Date(`1970-01-01T${event1?.start_time}`),
  //     to: new Date(`1970-01-01T${event1?.end_time}`),
  //     interval: event1?.interval,
  //     occur: event1?.recurring,
  //     // days:["Mon", "Tue", "Wed"]
  //     days: event1?.days ? event1?.days : [],
  //   }));

  //   updateEvent(event);
  //   settheme(event?.type);
  //   if (event?.id?.includes("default-event-id")) {
  //     toggle();
  //     // } else if (event1?.id) {
  //   } else {
  //     deletetoggleModal();
  //   }
  //   // deletetoggleModal();
  //   // setModal()
  // };

  // const {
  //   data: availableSlots,
  //   isLoading,
  //   isError,
  // } = useQuery({
  //   queryKey: ["get-available-slot", id],
  //   queryFn: () => getAvailableSlot1({ id }), // Call your API function
  //   staleTime: Infinity,
  // });
  
  // const handleEventClick = (info) => {
  //   const { eventDate } = formDataSlot;
  //   // const unavailableDates = availableSlots?.data?.[1]?.start_date;
  //   const unavailableDates = slotList?.data;


  //   let unavailable = false;
   
  //   unavailableDates.forEach((date) => {
  //     // if (date.start_time && date.end_time ) {
  //     //   Swal.fire({
  //     //     title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
  //     //   });
  //     //   unavailable = false;
  //     //   return;
  //     // }
  //     console.log("date.start_date",date.start_date)
  //     if (date.start_date  && date.start_time && date.end_time) {
  //       const startTime =convertTo12HourFormatphoto(date.start_time);
  //       const endTime = convertTo12HourFormatphoto(date.end_time);
      
  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
  //       });
  //       unavailable = false;
  //       return;
  //     }
     
  //     if (date.start_date  ) {
  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
  //       });
  //       unavailable = true;
  //       return;
  //     }
  //   });

  //   if (unavailable) {
  //     return;
  //   }
    
  // };
  const formatToAMPM = (dateString) => {
    const date = new Date(dateString);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleEventClick = (info) => {
    const { eventDate } = formDataSlot;
    const unavailableDates = slotList?.data;
  
    let unavailable = false;
  console.log("unavailableDates",unavailableDates)
    unavailableDates.forEach((date) => {
      console.log("info.event.startStr",info.event.startStr)
      console.log("info.event.startStr1",date.start_date)
      console.log("info.event.startStr2",formDataSlot.startTime)

      if (date.start_date && date.start_time && date.end_time) {
        const startTime = convertTo12HourFormatphoto(date.start_time);
        const endTime = convertTo12HourFormatphoto(date.end_time);
        const startTime1 = formatToAMPM(date.start_time);
        const endTime1 = formatToAMPM(date.end_time);
        // If a matching unavailable time slot is found
        if (date.start_date === info.event.startStr) {
          Swal.fire({
            title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${(startTime1)} to ${(endTime1)}`,
          });
          unavailable = false; // Mark as unavailable
          return; // Exit loop once an unavailable slot is found
        }
      }
  
      // If a matching date (without start_time and end_time) is found
      if (date.start_date === info.event.startStr && !date.start_time && !date.end_time) {
        Swal.fire({
          title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
        });
        unavailable = true; // Mark as unavailable
        return; // Exit loop once an unavailable slot is found
      }
    });
  
    if (unavailable) {
      return; // Exit if any slot is unavailable
    }
  
    // Proceed with the remaining logic if the slot is available
    // (Update the form data, set state, etc.)
  
    setFormDataSlot({
      ...formDataSlot,
      eventDate: info.event.startStr,
    });
  
    setSelectedDate(info.event.startStr);

    if (modalTop) {
      toggleTop(); // Close the modal
      console.log("Modal closed.");
    }
  
    const navigateOptions = {
      state: { eventDate: info.event.startStr, startTime: formDataSlot.startTime, endTime: formDataSlot.endTime, companyData },
    };
  
    // Navigate to the dj-bookevent page
    if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
      return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
    } else {
      return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
    }
  };
  

  // const handleEventClick = (info) => {
  //   const { eventDate } = formDataSlot;
  //   // const unavailableDates = availableSlots?.data?.[1]?.start_date;
  //   const unavailableDates = slotList?.data;


  //   let unavailable = false;

  //   unavailableDates.forEach((date) => {

  //     // if (date.start_time && date.end_time ) {
  //     //   Swal.fire({
  //     //     title: "DJ is Not Available! for this time " ,
  //     //   });
  //     //   unavailable = false;
  //     //   return;
  //     // }
  //     if (date.start_date === info.dateStr && date.start_time && date.end_time) {

        
  //       const startTime =convertTo12HourFormatphoto(date.start_time);
  //       const endTime = convertTo12HourFormatphoto(date.end_time);

      
  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
  //       });
  //       unavailable = false;
  //       return;
  //     }
      
  //     if (date.start_date === info.dateStr) {
  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
  //       });
  //       unavailable = true;
  //       return;
  //     }
  //   });

  //   if (unavailable) {
  //     return;
  //   }

  //   setFormDataSlot({
  //     ...formDataSlot,
  //     eventDate: info.dateStr,
  //   });
    
  //   setSelectedDate(info.dateStr);
  //   setFormData({ startdate: info.dateStr });
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     enddate: info.dateStr,
  //   }));
  //   console.log("eventDate form aoi",selectedDate)
   

  //   if (!cookieValue) {
  //     Swal.fire({
  //       title: "You are not Signed-In",
  //       text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
  //       imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
  //       imageWidth: 200,
  //       imageHeight: 200,
  //       imageAlt: "Sign-In Image",
  //       confirmButtonText: "Sign-In",
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-danger",
  //       },
  //       buttonsStyling: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         const now = new Date();
  //         const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
  //         Cookies.set("cookieVal", "clientRole3", {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         window.location = process.env.REACT_APP_ACCOUNT_URL;
  //       }
  //     });
  //     return;
  //   }

  //   if (modalTop) {
  //     toggleTop(); // Close the modal
  //     console.log("Modal closed.");
  //   }

  //   const navigateOptions = {
  //     state: { eventDate: info.dateStr, startTime: formDataSlot.startTime, endTime: formDataSlot.endTime, companyData },
  //   };

  //   if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  //   } else {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  //   }
  // };

  // const handleEventClick = (info) => {
  //   const { eventDate } = formDataSlot;
  //   // const unavailableDates = availableSlots?.data?.[1]?.start_date;
  //   const unavailableDates = slotList?.data;

    
  //   let unavailable = false;

  //   unavailableDates.forEach((date) => {
  //     console.log("info eventclick",info.dateStr)

  //     // if (date.start_time && date.end_time ) {
  //     //   Swal.fire({
  //     //     title: "DJ is Not Available! for this time " ,
  //     //   });
  //     //   unavailable = false;
  //     //   return;
  //     // }
  //     if (date.start_date === info.dateStr && date.start_time && date.end_time) {
  //       // const startTime = date.start_time;
  //       // const endTime = date.end_time;
  //       const startTime =convertTo12HourFormatphoto(date.start_time);
  //       const endTime = convertTo12HourFormatphoto(date.end_time);

  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
  //       });
  //       unavailable = false;
  //       return;
  //     }
      
  //     if (date.start_date === info.dateStr) {
  //       Swal.fire({
  //         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
  //       });
  //       unavailable = true;
  //       return;
  //     }
  //   });

  //   if (unavailable) {
  //     return;
  //   }

  //   setFormDataSlot({
  //     ...formDataSlot,
  //     eventDate: info.dateStr,
  //   });
  //   setSelectedDate(info.dateStr);
  //   setFormData({ startdate: info.dateStr });
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     enddate: info.dateStr,
  //   }));

   

  //   if (!cookieValue) {
  //     Swal.fire({
  //       title: "You are not Signed-In",
  //       text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
  //       imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
  //       imageWidth: 200,
  //       imageHeight: 200,
  //       imageAlt: "Sign-In Image",
  //       confirmButtonText: "Sign-In",
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-danger",
  //       },
  //       buttonsStyling: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         const now = new Date();
  //         const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
  //         Cookies.set("cookieVal", "clientRole3", {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         window.location = process.env.REACT_APP_ACCOUNT_URL;
  //       }
  //     });
  //     return;
  //   }

  //   if (modalTop) {
  //     toggleTop(); // Close the modal
  //     console.log("Modal closed.");
  //   }

  //   const navigateOptions = {
  //     state: { eventDate: info.dateStr, startTime: formDataSlot.startTime, endTime: formDataSlot.endTime, companyData },
  //   };

  //   if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  //   } else {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  //   }
  // };

  const {
    data: availableSlots,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["get-available-slot", id],
    queryFn: () => getAvailableSlot1(), // Call your API function
    staleTime: Infinity,
  });


  const profileimage = Links?.[activeVariable]?.Home?.[0]?.url4;

  useEffect(() => {
    const transformedAvailableSlots = Array.isArray(slotList?.data)
      ? slotList?.data?.map((slot) => ({
          id: slot.id,
          start_date: slot.start_date,
          // start_time: slot.start_time,
          // end_date: slot.end_date,
          // end_time: slot.end_time,
          // recurring: slot.recurring,
          // interval: slot.interval,
          // days: slot.days,
          reason: slot.reason,
        }))
      : [];
    if (transformedAvailableSlots.length > 0) {
      setSlotTransformData(transformedAvailableSlots);
    }
  }, [slotList]);

  const handleDelete = (eventid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // deleteavlSlot(eventid);
      }
      deletetoggleModal();
    });
  };

  useEffect(() => {
    reset(event);
  }, [event]);

  function editEvent() {
    naviget(`add-event?type=edit&id=${10}&from=fromEdit`);
    setModal1(false);
  }
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleModal = () => setModal(!modal);
  const deletetoggleModal = () => setDeleteModal(!deleteModal);
  const [formData, setFormData] = useState({
    startdate: "",
    enddate: "",
    From: "",
    to: "",
    occur: "",
    interval: "",
    byweekday: "",
    bymonthday: "",
    bymonth: ",",
    startTime: null,
    endTime: null,
  });

  const {
    data: companyData,
    isLoading: companyDataLoading,
    isError: companyDataError,
  } = useQuery({
    queryKey: ["get-company-by-id", id],
    queryFn: () => getAllCompanies({ id: id }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const toggleTop = () => setModalTop(!modalTop);

  const cookieValue = Cookies.get("authToken");
  let currentUrl;
  // const showSignMessege = () => {
  //   const { eventDate, startTime, endTime } = formDataSlot;

  //   if (!cookieValue) {
  //     Swal.fire({
  //       title: "You are not Sign-In",
  //       text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
  //       imageUrl: activeVariable === "CRM" ? NoFounds : NoFounds1,
  //       imageWidth: 200,
  //       imageHeight: 200,
  //       imageAlt: "Sign-In Image",
  //       confirmButtonText: "Sign-In",
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-danger",
  //       },
  //       buttonsStyling: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         const now = new Date();
  //         const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
  //         Cookies.set("cookieVal", "clientRole3", {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         window.location = process.env.REACT_APP_ACCOUNT_URL;
  //       }
  //     });
  //   } else if (!formDataSlot.startTime || !formDataSlot.endTime) {
  //     if (availableSlots1?.data.length === 0) {
  //       Swal.fire({
  //         title: "There is no slot available",
  //         text: "Please choose the another date.",
  //         icon: "warning",
  //         confirmButtonText: "OK",
  //         customClass: {
  //           confirmButton: "btn btn-primary",
  //         },
  //         buttonsStyling: false,
  //       });
  //     } else {
  //       Swal.fire({
  //         title: "Incomplete Booking Information",
  //         text: "Please choose the slots or select both a start time and an end time.",
  //         icon: "warning",
  //         confirmButtonText: "OK",
  //         customClass: {
  //           confirmButton: "btn btn-primary",
  //         },
  //         buttonsStyling: false,
  //       });
  //     }
  //     return;
  //   } else {
  //     // Close the modal first if it is open
  //     if (modalTop) {
  //       toggleTop(); // Close the modal
  //       console.log("Modal closed.");
  //     }

  //     if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
  //       return naviget(`/profiles/specific/${id}/dj-bookevent`, {
  //         state: { eventDate, startTime, endTime, companyData },
  //         // replace: true, // This will update the state without reloading or navigating
  //       });
  //     } else {
  //       return naviget(`/profiles/specific/${id}/dj-bookevent`, {
  //         state: { eventDate, startTime, endTime, companyData },
  //       });
  //     }
  //   }
  // };

  // const handleDateClick = (info) => {
  //   setShowcalendar(false);
  //   setShowicon(true);
  //   setFormDataSlot({
  //     ...formDataSlot,

  //     eventDate: info.dateStr,
  //     startTime: null,
  //     endTime: null,
  //   });
  //   setSelectedDate(info.dateStr);
  //   setFormData({ startdate: info.dateStr });
  //   const selectedDate1 = new Date(info.date);
  //   const clickedDay = selectedDate1.toLocaleDateString("en-US", { weekday: "short" });

  //   const day = clickedDay.toLowerCase().slice(0, 2);

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     enddate: info.dateStr,
  //   }));

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     byweekday: day,
  //   }));
  //   const monthday = selectedDate1.getDate();
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     bymonthday: monthday,
  //   }));
  //   const month = selectedDate1.getMonth() + 1;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     bymonth: month,
  //   }));

  //   toggleModal();
  // };

  // const handleDateClick = (info) => {
  //   if (info.dateStr === data) {
  //     Swal.fire({
  //       title: "DJ is Not Availabel!",})

  //     return;
  //   }

  //   console.log("Clicked date:", info.dateStr);
  // };

  // const handleDateClick = (info) => {
  //   const { eventDate} = formDataSlot;
  //   const unavailableDates = availableSlots?.data?.[1]?.start_date;
  // console.log("unavailableDates",unavailableDates)

  //   if (unavailableDates === info.dateStr ) {
  //     Swal.fire({
  //       title: "DJ is Not Available!",
  //     });
  //     return;
  //   }
  //   setFormDataSlot({
  //         ...formDataSlot,

  //         eventDate: info.dateStr,
  //       });
  //       setSelectedDate(info.dateStr);
  //   setFormData({ startdate: info.dateStr });
  //       setFormData((prevState) => ({
  //             ...prevState,
  //             enddate: info.dateStr,
  //           }));
  //   // console.log("unavailableDates.includes(info.dateStr)",unavailableDates.includes(info.dateStr))
  //   console.log("unavailableDates",unavailableDates)
  //   console.log("info.dateStr",info.dateStr)

  //   if (modalTop) {
  //     toggleTop(); // Close the modal
  //     console.log("Modal closed.");
  //   }

  //   if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, {
  //       state: { eventDate, companyData },
  //       // replace: true, // This will update the state without reloading or navigating
  //     });
  //   } else {
  //     return naviget(`/profiles/specific/${id}/dj-bookevent`, {
  //       state: { eventDate, companyData },
  //     });
  //   }
  // };

//   const handleDateClick = (info) => {
//     const { eventDate } = formDataSlot;
//     // const unavailableDates = availableSlots?.data?.[1]?.start_date;
//     const unavailableDates = slotList?.data;

// console.log("info 12",info)
//     let unavailable = false;

//     unavailableDates.forEach((date) => {
    
 
//       // if (date.start_time && date.end_time ) {
//       //   Swal.fire({
//       //     title: "DJ is Not Available! for this time " ,
//       //   });
//       //   unavailable = false;
//       //   return;
//       // }
//       if (date.start_date === info.dateStr && date.start_time && date.end_time) {
//         // const startTime = date.start_time;
//         // const endTime = date.end_time;
//         const startTime =convertTo12HourFormatphoto(date.start_time);
//         const endTime = convertTo12HourFormatphoto(date.end_time);

//         Swal.fire({
//           title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
//         });
//         unavailable = false;
//         return;
//       }
      
//       if (date.start_date === info.dateStr) {
//         Swal.fire({
//           title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
//         });
//         unavailable = true;
//         return;
//       }
//     });

//     if (unavailable) {
//       return;
//     }

//     setFormDataSlot({
//       ...formDataSlot,
//       eventDate: info.dateStr,
//     });
//     setSelectedDate(info.dateStr);
//     setFormData({ startdate: info.dateStr });
//     setFormData((prevState) => ({
//       ...prevState,
//       enddate: info.dateStr,
//     }));

   

//     if (!cookieValue) {
//       Swal.fire({
//         title: "You are not Signed-In",
//         text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
//         imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
//         imageWidth: 200,
//         imageHeight: 200,
//         imageAlt: "Sign-In Image",
//         confirmButtonText: "Sign-In",
//         showCancelButton: true,
//         customClass: {
//           confirmButton: "btn btn-primary",
//           cancelButton: "btn btn-danger",
//         },
//         buttonsStyling: false,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           const now = new Date();
//           const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
//           Cookies.set("cookieVal", "clientRole3", {
//             domain: process.env.REACT_APP_COOKIE_DOMAIN,
//             expires: expires,
//           });
//           Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
//             domain: process.env.REACT_APP_COOKIE_DOMAIN,
//             expires: expires,
//           });
//           window.location = process.env.REACT_APP_ACCOUNT_URL;
//         }
//       });
//       return;
//     }

//     if (modalTop) {
//       toggleTop(); // Close the modal
//       console.log("Modal closed.");
//     }

//     const navigateOptions = {
//       state: { eventDate: info.dateStr, startTime: formDataSlot.startTime, endTime: formDataSlot.endTime, companyData },
//     };

//     if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
//       return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
//     } else {
//       return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
//     }
//   };
const handleDateClick = (info) => {
  const { eventDate } = formDataSlot;
  const unavailableDates = slotList?.data;

  console.log("info 12", info);
  let unavailable = false;
  let startTime = null;
  let endTime = null;

  unavailableDates.forEach((date) => {
    if (date.start_date === info.dateStr && date.start_time && date.end_time) {
      const formattedStartTime = new Date(date.start_time).toString(); // Format start time
      const formattedEndTime = new Date(date.end_time).toString(); // Format end time
      const startTime1 = formatToAMPM(date.start_time);
        const endTime1 = formatToAMPM(date.end_time);

      Swal.fire({
        title: `The ${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is not available for the time slot:  ${startTime1} to ${endTime1}`,
      });
      unavailable = false;
      return;
    }

    if (date.start_date === info.dateStr) {
      Swal.fire({
        title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
      });
      unavailable = true;
      return;
    }
  });

  if (unavailable) {
    return;
  }

  setFormDataSlot({
    ...formDataSlot,
    eventDate: info.dateStr,
  });
  setSelectedDate(info.dateStr);
  setFormData({ startdate: info.dateStr });
  setFormData((prevState) => ({
    ...prevState,
    enddate: info.dateStr,
  }));

  if (!cookieValue) {
    Swal.fire({
      title: "You are not Signed-In",
      text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
      imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: "Sign-In Image",
      confirmButtonText: "Sign-In",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
        Cookies.set("cookieVal", "clientRole3", {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        window.location = process.env.REACT_APP_ACCOUNT_URL;
      }
    });
    return;
  }

  if (modalTop) {
    toggleTop(); // Close the modal
    console.log("Modal closed.");
  }

  const navigateOptions = {
    state: {
      eventDate: info.dateStr,
      startTime: startTime || formDataSlot.startTime,
      endTime: endTime || formDataSlot.endTime,
      companyData,
    },
  };

  if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
    return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  } else {
    return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
  }
};

// const handleDateClick = (info) => {
//   const { eventDate } = formDataSlot;
//   const unavailableDates = slotList?.data;

//   console.log("info 12", info);
//   let unavailable = false;
//   let startTime = null;
//   let endTime = null;

//   unavailableDates.forEach((date) => {
//     if (date.start_date === info.dateStr && date.start_time && date.end_time) {
//       startTime = convertTo12HourFormatphoto(date.start_time);
//       endTime = convertTo12HourFormatphoto(date.end_time);

//       Swal.fire({
//         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available! for this time: ${startTime} to ${endTime}`,
//       });
//       unavailable = false;
//       return;
//     }

//     if (date.start_date === info.dateStr) {
//       Swal.fire({
//         title: `${conatct?.[activeVariable]?.section1?.map((item) => item?.textdj)} is Not Available!`,
//       });
//       unavailable = true;
//       return;
//     }
//   });

//   if (unavailable) {
//     return;
//   }

//   setFormDataSlot({
//     ...formDataSlot,
//     eventDate: info.dateStr,
//   });
//   setSelectedDate(info.dateStr);
//   setFormData({ startdate: info.dateStr });
//   setFormData((prevState) => ({
//     ...prevState,
//     enddate: info.dateStr,
//   }));

//   if (!cookieValue) {
//     Swal.fire({
//       title: "You are not Signed-In",
//       text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
//       imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
//       imageWidth: 200,
//       imageHeight: 200,
//       imageAlt: "Sign-In Image",
//       confirmButtonText: "Sign-In",
//       showCancelButton: true,
//       customClass: {
//         confirmButton: "btn btn-primary",
//         cancelButton: "btn btn-danger",
//       },
//       buttonsStyling: false,
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const now = new Date();
//         const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
//         Cookies.set("cookieVal", "clientRole3", {
//           domain: process.env.REACT_APP_COOKIE_DOMAIN,
//           expires: expires,
//         });
//         Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${id}`, {
//           domain: process.env.REACT_APP_COOKIE_DOMAIN,
//           expires: expires,
//         });
//         window.location = process.env.REACT_APP_ACCOUNT_URL;
//       }
//     });
//     return;
//   }

//   if (modalTop) {
//     toggleTop(); // Close the modal
//     console.log("Modal closed.");
//   }

//   const navigateOptions = {
//     state: {
//       eventDate: info.dateStr,
//       startTime: startTime || formDataSlot.startTime,
//       endTime: endTime || formDataSlot.endTime,
//       companyData,
//     },
//   };

//   if (window.location.pathname === `/profiles/specific/${id}/dj-bookevent`) {
//     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
//   } else {
//     return naviget(`/profiles/specific/${id}/dj-bookevent`, navigateOptions);
//   }
// };


  const data = [
    {
      id: "default-event-id-" + 1,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [8], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-08-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
    {
      id: "default-event-id-" + 2,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [9], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-09-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
  ];

  const [availableTimes, setAvailableTimes] = useState([]);

  // Helper function to generate time intervals between start and end times
  const generateTimeSlots = (startTime, endTime, interval = 5) => {
    const timeSlots = [];
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      timeSlots.push(new Date(currentTime)); // Store the current time slot
      currentTime = new Date(currentTime.getTime() + interval * 60 * 1000); // Increment by interval (5 mins)
    }

    return timeSlots;
  };

  // function convertTo12HourFormat(time24) {
  //   const [hour, minute] = time24.split(":");
  //   let hourNum = parseInt(hour, 10);
  //   const ampm = hourNum >= 12 ? "PM" : "AM";
  //   hourNum = hourNum % 12 || 12;
  //   return `${hourNum}:${minute.padStart(2, "0")} ${ampm}`;
  // }

  const rruleFormatData = slotList?.data?.map((data) => {
    // const startTime12 = convertTo12HourFormat(data.start_time);
    // const endTime12 = convertTo12HourFormat(data.end_time);
    const reason = data.reason;

    const sday = new Date(data.start_date).getDate();
    const eday = new Date(data.start_date).getDate();
    // const recurring = data.recurring === null || undefined ? "daily" : data.recurring?.toLowerCase();
    const recurring = "";
 
    return {
      id: "default-slot-id-" + data.id,
      // title: `${startTime12} to ${endTime12}`,
      // title: `${reason}`,
      title: `${reason ? "Time Off" : "Time Off"}`,
      // rrule: {
        // freq: recurring,
      // interval: data.interval ? JSON.parse(data.interval) : 1, // default interval if missing
      // byweekday: recurring === "daily" || recurring === "monthly" || recurring === "yearly" ? null : [data.byweekday],
      // bymonthday:
      //   recurring === "yearly" || (recurring === "monthly" && sday <= eday)
      //     ? Array.from({ length: eday - sday + 1 }, (_, i) => i + sday) // Create array from sday to eday
      //     : data.bymonthday && recurring !== "weekly" && recurring !== "daily"
      //     ? [parseInt(data.bymonthday, 10)]
      //     : null,
      // bymonth: recurring === "yearly" ? [parseInt(data.bymonth, 10)] : null,
      // dtstart: data.start_date,
      // until: recurring === "yearly" ? null : data.end_date || null,
      // },
      color: "black",
      type: "slot",
      start: `${data?.start_date}`,
      end: `${data?.start_date}`,
      backgroundColor: "black",
    };
  });

  const totalData = [...(rruleFormatData || [])];
  // const totalData = [
  //   ...([
  //     {
  //       // this object will be "parsed" into an Event Object
  //       title: "The Title", // a property!
  //       start: "2025-01-11", // a property!
  //       end: "2025-01-12", // a property! ** see important note below about 'end' **
  //     },
  //     {
  //       // this object will be "parsed" into an Event Object
  //       title: "The dsfase Title", // a property!
  //       start: "2025-01-21", // a property!
  //       end: "2025-01-22", // a property! ** see important note below about 'end' **
  //     },
  //   ] || []),
  // ];
  // Initially set to 'all' to show all slots
  const [selectedSlots, setSelectedSlots] = useState({
    all: true, // Initially all checkboxes are unchecked except 'all'
    morning: false,
    afternoon: false,
    night: false,
  });

  const [filter, setFilter] = useState(["all"]);

  // Your existing code for rendering the slots remains unchanged
  const handleCheckboxChange = (e) => {
    const { name } = e.target;

    setSelectedSlots((prev) => {
      const updatedSlots = {
        morning: false,
        afternoon: false,
        night: false,
        all: false,
      };

      // Set the selected time period to true
      if (name === "all") {
        updatedSlots.all = true;
      } else {
        updatedSlots[name] = true;
      }

      return updatedSlots; // Return the updated slots state
    });
  };

  const formatTime = (date) => {
    return date ? date.toLocaleString([], { hour: "2-digit", minute: "2-digit", hour12: true }) : "";
  };

  const [interval1, setInterval1] = useState(60);

  const {
    data: availableSlots1,
    isLoading: availableSlotLoading,
    isError: availableSlotError,
  } = useQuery({
    queryKey: ["get-available-slot", id, selectedDate, interval1],
    queryFn: () =>
      getAvailableSlot({
        id,
        date: selectedDate,
        interval1,
      }),
    staleTime: Infinity,
  });
  const getFilteredSlots = (selectedSlots, slotList) => {
    const convertTo24Hour = (time12h) => {
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes, seconds] = time.split(":").map(Number);
      if (modifier === "PM" && hours < 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    };

    if (selectedSlots.all) {
      return slotList?.data || [];
    }

    let combinedSlots = [];

    // Filter by time range
    // if (selectedSlots.morning) {
    //   combinedSlots = availableSlots?.data.filter((slot) => {
    //     const start24 = convertTo24Hour(slot.start);
    //     return start24 >= "06:00:00" && start24 < "12:00:00";
    //   });
    // }
    // if (selectedSlots.afternoon) {
    //   combinedSlots = [
    //     ...combinedSlots,
    //     ...availableSlots?.data.filter((slot) => {
    //       const start24 = convertTo24Hour(slot.start);
    //       return start24 >= "12:00:00" && start24 < "18:00:00";
    //     }),
    //   ];
    // }
    // if (selectedSlots.night) {
    //   combinedSlots = [
    //     ...combinedSlots,
    //     ...availableSlots?.data.filter((slot) => {
    //       const start24 = convertTo24Hour(slot.start);
    //       return start24 >= "18:00:00" && start24 <= "23:59:59";
    //     }),
    //   ];
    // }
    return (combinedSlots?.length || 0) > 0 ? combinedSlots : [];
  };
  function setTimeForToday(timeString) {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time.split(":");
    hours = parseInt(hours, 10);

    // Convert to 24-hour format if PM
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    // Create a new Date object with today's date
    const today = new Date();
    today.setHours(hours, parseInt(minutes, 10), seconds ? parseInt(seconds, 10) : 0, 0);
    return today;
  }
  useEffect(() => {
    const buttons = document.querySelectorAll(
      ".fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-listWeek-button, .fc-dayGridMonth-button"
    );

    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        buttons.forEach((btn) => btn.classList.remove("active"));

        this.classList.add("active");
      });
    });

    const defaultButton = document.querySelector(".fc-dayGridMonth-button");
    if (defaultButton) {
      defaultButton.classList.add("active");
    }

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", function () {});
      });
    };
  }, []);

  function convertToHHMM12Hour(timeString) {
    const [time, modifier] = timeString.split(" "); // Split the time and AM/PM
    const [hours, minutes] = time.split(":"); // Extract hours and minutes
    return `${hours}: ${minutes} ${modifier}`; // Return in hh mm AM/PM format
  }



  return (
    // <React.Fragment>
    //   {showcalendar ? (
    //     <FullCalendar
    //       plugins={[
    //         resourceTimelinePlugin,
    //         dayGridPlugin,
    //         timeGridPlugin,
    //         listPlugin,
    //         bootstrapPlugin,
    //         interactionPlugin,
    //         rrulePlugin,
    //       ]}
    //       events={totalData}
    //       schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    //       eventClick={handleEventClick}
    //       dateClick={handleDateClick}
    //       initialView="customMonth"
    //       nowIndicator={true}
    //       // initialView ="dayGridMonth"
    //       // resources={availableSlots?.data}
    //       dayMaxEventRows={2}
    //       headerToolbar={{
    //         left: "title prev,next",
    //         center: null,
    //         right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
    //       }}
    //       themeSystem="bootstrap5"
    //       height={560}
    //       contentHeight={780}
    //       // eventContent={(e) => <EventView event={e} />}

    //       aspectRatio={3}
    //       editable={true}
    //       droppable={true}
    //       views={{
    //         customMonth: {
    //           type: "dayGridMonth",
    //           fixedWeekCount: false,
    //         },
    //       }}
    //       validRange={{
    //         start: new Date(),
    //       }}
    //     />
    //   ) : (
    //     <>
    //       {availableSlotLoading ? (
    //         <Row>
    //           <Col lg={4}>
    //             <div className="p-5">
    //               <ShimmerThumbnail height={400} width={300} />
    //             </div>
    //           </Col>
    //           <Col lg={4}>
    //             <div className="p-5">
    //               <ShimmerThumbnail height={400} width={700} />
    //             </div>
    //           </Col>
    //         </Row>
    //       ) : (
    //         <div className="row h-100">
    //           <div className="col-lg-3 p-0">
    //             <div className="leaflet-bar shadow border border-primary h-100" style={{ overflowY: "auto" }}>
    //               <img
    //                 alt="profile-image"
    //                 src={availableSlots1?.company_profile || profileimage}
    //                 className="card---img card-img-top img-fluid object-fit-cover"
    //               />
    //               <div className="container"></div>

    //               <div className="justify-center mt-2">
    //                 <div
    //                   className="d-flex"
    //                   style={{
    //                     maxHeight: "250px",
    //                     padding: "10px",
    //                   }}
    //                 >
    //                   {availableSlots1?.uniqueness}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-lg-9 mt-2">
    //             {selectedDate && (
    //               <div className="">
    //                 <h6 className="mb-4">Event Date: {new Date(selectedDate).toISOString().split("T")[0]}</h6>
    //               </div>
    //             )}
    //             <div className="card---container d-flex flex-row align-items-center justify-content-center">
    //               <label htmlFor="allCheckbox" className="card--- d-flex align-items-center justify-content-center">
    //                 <div className="card---cover d-flex align-items-center fs-4 form-check">
    //                   <input
    //                     type="radio"
    //                     className="form-check-input me-2"
    //                     name="all"
    //                     id="allCheckbox"
    //                     checked={selectedSlots.all}
    //                     onChange={handleCheckboxChange}
    //                   />
    //                   <label htmlFor="allCheckbox" className="card---title form-check-label mt-1">
    //                     All
    //                   </label>
    //                 </div>
    //               </label>
    //               <label htmlFor="morningCheckbox" className="card--- d-flex align-items-center justify-content-center">
    //                 <div className="card---cover d-flex align-items-center fs-4 form-check">
    //                   <input
    //                     type="radio"
    //                     className="form-check-input me-2"
    //                     name="morning"
    //                     id="morningCheckbox"
    //                     checked={selectedSlots.morning}
    //                     onChange={handleCheckboxChange}
    //                   />
    //                   <label htmlFor="morningCheckbox" className="card---title form-check-label mt-1">
    //                     Morning
    //                   </label>
    //                 </div>
    //               </label>

    //               <label
    //                 htmlFor="afternoonCheckbox"
    //                 className="card--- d-flex align-items-center justify-content-center"
    //               >
    //                 <div className="card---cover d-flex align-items-center fs-4 form-check">
    //                   <input
    //                     type="radio"
    //                     className="form-check-input me-2"
    //                     name="afternoon"
    //                     id="afternoonCheckbox"
    //                     checked={selectedSlots.afternoon}
    //                     onChange={handleCheckboxChange}
    //                   />
    //                   <label htmlFor="afternoonCheckbox" className="card---title form-check-label mt-1">
    //                     Afternoon
    //                   </label>
    //                 </div>
    //               </label>

    //               <label htmlFor="nightCheckbox" className="card--- d-flex align-items-center justify-content-center">
    //                 <div className="card---cover d-flex align-items-center fs-4 form-check">
    //                   <input
    //                     type="radio"
    //                     className="form-check-input me-2"
    //                     name="night"
    //                     id="nightCheckbox"
    //                     checked={selectedSlots.night}
    //                     onChange={handleCheckboxChange}
    //                   />
    //                   <label htmlFor="nightCheckbox" className="card---title form-check-label mt-1">
    //                     Night
    //                   </label>
    //                 </div>
    //               </label>
    //             </div>

    //             <div className="d-flex flex-column h-60 p-3">
    //               {/* Start Time Picker */}

    //               <div className="w-100 mt-2">
    //                 <div className="d-flex flex-column flex-md-row justify-content-between">
    //                   <div className="form-control-wrap has-timepicker flex-grow-1 mb-3 mx-1">
    //                     <label>Start Time</label>
    //                     <DatePicker
    //                       disabled={availableSlots1?.data.length === 0}
    //                       minTime={
    //                         availableSlots1?.startAndEndTime?.[0]?.start_time
    //                           ? setTimeForToday(availableSlots1.startAndEndTime[0].start_time)
    //                           : new Date().setHours(0, 0, 0, 0) // fallback to midnight if no start_time
    //                       }
    //                       maxTime={
    //                         availableSlots1?.startAndEndTime?.[0]?.end_time
    //                           ? setTimeForToday(availableSlots1.startAndEndTime[0].end_time)
    //                           : new Date().setHours(23, 59, 59, 999) // fallback to end of the day if no end_time
    //                       }
    //                       selected={formDataSlot.startTime}
    //                       onChange={(date) => setFormDataSlot({ ...formDataSlot, startTime: date })}
    //                       showTimeSelect
    //                       showTimeSelectOnly
    //                       timeIntervals={15}
    //                       timeCaption="Time"
    //                       dateFormat="h:mm aa"
    //                       placeholderText="Select start time"
    //                       className="form-control date-picker"
    //                     />
    //                   </div>
    //                   <div className="form-control-wrap has-timepicker flex-grow-1 mx-1">
    //                     <label>End Time</label>
    //                     <DatePicker
    //                       minTime={
    //                         formDataSlot.startTime
    //                           ? new Date(new Date(formDataSlot.startTime).getTime() + 15 * 60 * 1000) // 15 minutes before starttime
    //                           : new Date().setHours(0, 0, 0, 0) // fallback to midnight if no starttime
    //                       }
    //                       maxTime={
    //                         availableSlots1?.startAndEndTime?.[0]?.end_time
    //                           ? setTimeForToday(availableSlots1.startAndEndTime[0].end_time)
    //                           : new Date().setHours(23, 59, 59, 999) // fallback to end of the day if no endtime
    //                       }
    //                       selected={formDataSlot.endTime}
    //                       onChange={(date) => setFormDataSlot({ ...formDataSlot, endTime: date })}
    //                       showTimeSelect
    //                       showTimeSelectOnly
    //                       timeIntervals={15}
    //                       timeCaption="Time"
    //                       dateFormat="h:mm aa"
    //                       placeholderText="Select end time"
    //                       className="form-control date-picker"
    //                       disabled={!formDataSlot.startTime}
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="h-100 mt-md-0 mt-3  fs-3 text-center">
    //                 {getFilteredSlots(selectedSlots, availableSlots1).length > 0 ? (
    //                   <label className="">Available Slot</label>
    //                 ) : (
    //                   ""
    //                 )}
    //                 <div className="badge---card mt-3">
    //                   {availableSlots1?.data && (
    //                     <div
    //                       className="slider-container justify-content-center w-100"

    //                     >
    //                       <div className="badge---card border-bottom  mt-3 d-flex flex-row align-items-center justify-content-center ">
    //                         {getFilteredSlots(selectedSlots, availableSlots1).length > 0 ? (
    //                           getFilteredSlots(selectedSlots, availableSlots1).map((slot, index) => (
    //                             <div key={index} className="mx-2 justify-content-center">
    //                               <label>
    //                                 <Badge pill color="light" className="card---badge w-100">
    //                                   <input
    //                                     type="radio"
    //                                     className="me-2"
    //                                     style={{ cursor: "pointer" }}
    //                                     name="timeSlot" // Ensure radio buttons are grouped by name
    //                                     onChange={(e) => {
    //                                       if (e.target.checked) {
    //                                         setFormDataSlot({
    //                                           ...formDataSlot,
    //                                           startTime: new Date(slot?.start_date_time),
    //                                           endTime: new Date(slot?.end_date_time),
    //                                         });
    //                                       }
    //                                     }}
    //                                   />
    //                                   <div className="fs-6">
    //                                     {slot?.start} to {slot?.end}
    //                                   </div>
    //                                 </Badge>
    //                               </label>
    //                             </div>
    //                           ))
    //                         ) : (
    //                           <div className="mb-2 w-100 w-md-75 mx-auto ">
    //                             {selectedSlots.morning && <div className="mb-2">No morning slots available.</div>}
    //                             {selectedSlots.afternoon && <div className="mb-2">No afternoon slots available.</div>}
    //                             {selectedSlots.night && <div className="mb-2">No night slots available.</div>}
    //                             {selectedSlots.all && (
    //                               <div className="text-center justify-content-center mb-2">No slots available.</div>
    //                             )}
    //                           </div>
    //                         )}
    //                       </div>
    //                     </div>
    //                   )}
    //                 </div>
    //                 <div className="d-flex justify-content-center mt-2">
    //                   <Button color="primary" className="" onClick={showSignMessege}>
    //                     Book Now
    //                   </Button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //     </>
    //   )}
    // </React.Fragment>

    <FullCalendar
  plugins={[
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    bootstrapPlugin,
    interactionPlugin,
    rrulePlugin,
  ]}
  events={totalData}
  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
  eventClick={handleEventClick}
  dateClick={handleDateClick}
  initialView="dayGridMonth"
  nowIndicator={true}
  dayMaxEventRows={2}
  headerToolbar={{
    left: "title prev,next",
    center: null,
    right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
  }}
  themeSystem="bootstrap5"
  height={560}
  contentHeight={780}
  aspectRatio={3}
  editable={true}
  droppable={true}
  views={{
    customMonth: {
      type: "dayGridMonth",
      fixedWeekCount: false,
    },
  }}
  validRange={() => {
    const today = new Date();
    return {
      start: today.toISOString().split("T")[0], // Allows navigation while restricting past date selection
    };
  }}
/>

    // <FullCalendar
    //   plugins={[
    //     dayGridPlugin,
    //     timeGridPlugin,
    //     listPlugin,
    //     bootstrapPlugin,
    //     interactionPlugin,
    //     rrulePlugin,
    //   ]}
    //   events={totalData}
    //   schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    //   eventClick={handleEventClick}
    //   dateClick={handleDateClick}
    //   // initialView="customMonth"
    //   initialView="dayGridMonth"
    //   nowIndicator={true}
    //   // initialView ="dayGridMonth"
    //   // resources={availableSlots?.data}
    //   dayMaxEventRows={2}
    //   headerToolbar={{
    //     left: "title prev,next",
    //     center: null,
    //     right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
    //   }}
    //   themeSystem="bootstrap5"
    //   height={560}
    //   contentHeight={780}
    //   // eventContent={(e) => <EventView event={e} />}

    //   aspectRatio={3}
    //   editable={true}
    //   droppable={true}
    //   views={{
    //     customMonth: {
    //       type: "dayGridMonth",
    //       fixedWeekCount: false,
    //     },
    //   }}
    //   validRange={{
    //     start: new Date(),
    //   }}
    // />
  );
};

export default CalenderApp;
